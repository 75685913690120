import React from "react";
import { Link, graphql } from "gatsby";
import Img from "gatsby-image";
import Helmet from "react-helmet";
import Layout from "components/Layout";
import Button from "components/button";
import Form from "components/form";
import { Container, Card, CardBody, CardTitle, Row, Col } from "reactstrap";
import {
  Section,
  HomeSection,
  LightGreySection,
  DarkGreySection
} from "components/section";
import styled from "styled-components";

const TickList = styled.ul`
  list-style: none;
  font-size: 1.3rem;
  li {
    margin-left: 30px;
    margin-bottom: 10px;
  }
  li::before {
    content: "✓";
    color: ${props => props.theme.orange || `orange`};
    position: absolute;
    margin-left: -30px;
    font-size: 1.5rem;
  }
`;

const IndexPage = ({ data }) => {
  let services = data.allMarkdownRemark.edges;

  return (
    <Layout>
      <Helmet>
        <title>
          CleanWright - Professional Carpet Cleaning in Taunton & Wellington
        </title>
      </Helmet>
      <HomeSection>
        <Container>
          <div className="home-left mb-5">
            <h1>
              Professional carpet, curtain and upholstery cleaning. done
              <span className="orange">wright</span>.
            </h1>
            <ul>
              <li>
                Areas covered: Taunton, Wellington, Exeter, Bridgwater and
                everything in between.
              </li>
              <li>Available 7 days a week</li>
              <li>Call now for a free quote on 01823 666025</li>
            </ul>
          </div>
          <div className="home-right">
            <h3 className="mb-4">Arrange a callback</h3>
            <Form name="Home Form" />
          </div>
        </Container>
      </HomeSection>
      <DarkGreySection>
        <Container>
          <h2>Why Choose CleanWright?</h2>
          <TickList className="mb-5">
            <li>
              We are specialist carpet cleaners using the latest cleaning
              technology
            </li>
            <li>Experts in upholstery, rug, and mattress cleaning</li>
            <li>
              Spend over £120 and we will Scotch Guard your carpet for free
            </li>
            <li>
              Protect your carpet by using Scotch Guard, insecticides,
              deodorisers &amp; allergy protection
            </li>
            <li>
              We don’t hide any of our costs – our service is all inclusive and
              you’ll know exactly what you’re getting for your money, up front.
            </li>
          </TickList>
          <h3>
            Call <span className="orange">01823 666025</span> now to book a
            carpet clean
          </h3>
          <p className="text-center">or</p>
          <p className="text-center">
            <Button tag={Link} to="/quote">
              Arrange a Callback
            </Button>
          </p>
        </Container>
      </DarkGreySection>
      <Section>
        <Container>
          <h2>Our Services</h2>
          <Row>
            {services.map(({ node }) => (
              <Col className="mb-4" md="4" key={node.fields.slug}>
                <Card className="shadow-sm h-100">
                  <Img
                    alt={node.frontmatter.title}
                    fluid={node.frontmatter.image.childImageSharp.fluid}
                    className="mb-4"
                  />
                  <CardTitle>
                    <h3>
                      <Link to={`/service${node.fields.slug}`}>
                        {node.frontmatter.title}
                      </Link>
                    </h3>
                  </CardTitle>
                  <CardBody className="text-center">
                    <p>{node.excerpt}</p>
                    <Link to={`/service${node.fields.slug}`}>Read More</Link>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </Section>
      <LightGreySection>
        <Container>
          <h2>What Our Clients Say</h2>
          <Row className="text-center">
            <Col md="4">
              <h4>Helen</h4>
              <p className="orange">★★★★★</p>
              <p>
                “Steve did a wonderful job on my carpets, l was impressed with
                the way he did the work too – professional and friendly. I have
                no hesitation in recommending this company.”
              </p>
            </Col>
            <Col md="4">
              <h4>Chloe</h4>
              <p className="orange">★★★★★</p>
              <p>
                “Having bought a second hand sofa, we wanted to make sure it was
                clean and stain-free before having it in our house. CleanWright
                did a very thorough job of cleaning it and removing unwanted
                marks. The service was very efficient and friendly. Thanks!”
              </p>
            </Col>
            <Col md="4">
              <h4>Matthew</h4>
              <p className="orange">★★★★★</p>
              <p>
                “We have used Cleanwright on several occasions and have always
                been pleased with the results. Our Son was especially pleased
                when Steve was able to remove a greasy stain from his classic
                car seat. We will certainly be using their services again.”
              </p>
            </Col>
          </Row>
        </Container>
      </LightGreySection>
      <Section>
        <Container>
          <h2>Areas Covered</h2>
          <p className="text-center mb-5">
            CleanWright is based in Wellington, but we offer our carpet cleaning
            services across Somerset. This includes Taunton, Exeter, Bridgwater,
            Chard and everywhere in between.
          </p>
          <iframe
            style={{ border: 0 }}
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d90787.27839160293!2d-3.2539176452884053!3d50.97176092052763!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x486d8cafdffbc5c3%3A0xcea5dac168f6d470!2sWellington!5e0!3m2!1sen!2suk!4v1532899751155"
            width="100%"
            height="450"
            frameBorder="0"
            allowFullScreen="allowfullscreen"
            title="Areas Covered"
          ></iframe>
        </Container>
      </Section>
      <DarkGreySection className="text-center">
        <Container>
          <h2>Special Offer!</h2>
          <h4>
            Spend over £120 and we will scotch guard a carpet of your choice for
            free!
          </h4>
          <p>
            Call <span className="orange">01823 666025</span> now to claim your
            offer
          </p>
          <p className="text-center">or</p>
          <p className="text-center">
            <Button tag={Link} to="/quote">
              Arrange a Callback
            </Button>
          </p>
        </Container>
      </DarkGreySection>
      <LightGreySection>
        <Container>
          <h2>When You Need To Act Fast!</h2>
          <ul>
            <li>
              We can professionally{" "}
              <Link to="/service/allergy-protection/">
                eliminate carpet moths
              </Link>
              , fleas, dust mites and any other infestations or allergens that
              are in your carpets
            </li>
            <li>
              For unexpected accidents, such as{" "}
              <Link to="/service/scotch-guard-protection/">
                food and drink spillages
              </Link>{" "}
              or child and pet mishaps, the faster you act, the more likely the
              stain can be removed.
            </li>
            <li>
              If you need your carpets cleaned urgently we can rearrange our
              schedule to accommodate your needs.
            </li>
          </ul>
          <h3>
            Call <span className="text-orange">01823 666025</span> now to
            arrange an urgent visit
          </h3>
          <p className="text-center">or</p>
          <p className="text-center">
            <Button>Arrange a Callback</Button>
          </p>
        </Container>
      </LightGreySection>
      <Section>
        <Container>
          <h2>About Me</h2>
          <Row>
            <Col sm={4}>
              <Img
                alt="Steve Wright, carpet cleaner in Somerset"
                fluid={data.steve.childImageSharp.fluid}
              />
            </Col>
            <Col sm={8}>
              <p>
                My name is Steve Wright, and I have been a carpet cleaner for
                over 9 years. Since starting as a carpet cleaner I have dealt
                with all kinds of jobs, from small spills in domestic properties
                up to large hotels, care homes and end of tenancy cleans.
              </p>
              <p>I offer carpet cleaning in these areas:</p>
              <ul>
                <li>Taunton</li>
                <li>Wellington</li>
                <li>Bridgwater</li>
                <li>Exeter</li>
                <li>Ilminster</li>
                <li>Chard</li>
              </ul>
            </Col>
          </Row>
        </Container>
      </Section>
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  {
    allMarkdownRemark(filter: { frontmatter: { featured: { eq: true } } }) {
      edges {
        node {
          frontmatter {
            title
            image {
              childImageSharp {
                fluid(maxWidth: 500, maxHeight: 300) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
          }
          excerpt(pruneLength: 250)
        }
      }
    }
    steve: file(relativePath: { eq: "steve-wright.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
